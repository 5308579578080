(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/content-handler/assets/javascripts/content-handler.js') >= 0) return;  svs.modules.push('/components/sportinfo/content-handler/assets/javascripts/content-handler.js');
"use strict";

const logger = svs.core.log.getLogger('sport:sportinfo-content-handler');
const {
  useEffect,
  useState
} = React;
const {
  getMenuConfig,
  getContent
} = svs.components.sportinfo.configs.ContentConfig;
const {
  matchState: {
    getSportInfoMatchState
  }
} = svs.components.sportinfo.common;
const {
  config
} = svs.components.sportinfo.sportinfoContentView;
const {
  injectAnalyze
} = svs.components.sportinfo.configs.InjectTab;
const {
  lazy
} = svs.components.lbUtils.lazy;
const {
  SkeletonWrapper
} = svs.components.sportinfo.skeletonWrapper;
const {
  useExternalMatchIds
} = svs.components.sport.statisticsCommon;
const {
  detect
} = svs.core;
const {
  useHistory
} = ReactRouterDOM;
const useEnetpulseIds = () => {
  const history = useHistory();
  const hasEnetpulse = detect.feature('ft-enetpulse-stats');
  const idsForSessionStorage = {};
  if (hasEnetpulse) {
    const qs = new URLSearchParams(history.location.search);
    const enetpulseIds = qs.get('enetpulse');
    if (!enetpulseIds) {
      return;
    }
    qs.delete('enetpulse');
    const games = enetpulseIds.split(',');
    games.forEach(game => {
      const [svsId, enetpulseId] = game.split(':');
      if (svsId.match(/^\d+/) && enetpulseId.match(/^\d+/)) {
        idsForSessionStorage[svsId] = enetpulseId;
      }
    });
  }
  if (Object.keys(idsForSessionStorage).length) {
    sessionStorage.setItem('enetpulseIds', JSON.stringify(idsForSessionStorage));
  }
};
const ContentHandler = _ref => {
  let {
    matchId,
    sportType,
    forceSportType,
    forceFetchSportEvent = false,
    sportEventStatus,
    children,
    productId,
    shouldIncludeExtraStats,
    lazyLoader = lazy
  } = _ref;
  const [contentConfig, setContentConfig] = useState({
    menuItems: [],
    content: {},
    matchStateProps: {},
    lazyComponentsMap: undefined
  });
  const {
    externalId
  } = useExternalMatchIds(matchId);
  useEnetpulseIds();
  const setContentStates = React.useCallback((sportType, sportEventStatus) => {
    const finalSportType = forceSportType !== null && forceSportType !== void 0 ? forceSportType : sportType;
    const matchState = getSportInfoMatchState(sportEventStatus);
    let filteredMenuItems = getMenuConfig({
      sportType: finalSportType,
      productId,
      providerIds: externalId,
      matchState
    });
    let filteredContent = getContent({
      sportType: finalSportType,
      matchState,
      productId,
      providerIds: externalId
    });
    if (shouldIncludeExtraStats) {
      ({
        menuItems: filteredMenuItems,
        content: filteredContent
      } = injectAnalyze(filteredMenuItems, filteredContent, matchState));
    }
    const _lazyComponentsMap = new Map();
    Object.keys(filteredContent).forEach(tab => {
      filteredContent[tab].forEach(content => {
        const componentConfig = config[content.provider](content.type);
        if (componentConfig) {
          const {
            component,
            componentRef,
            skeletonConfig
          } = componentConfig;
          const config = skeletonConfig(content.type);
          _lazyComponentsMap.set(component, lazyLoader(component, componentRef, {
            fallback: () => React.createElement(SkeletonWrapper, {
              config: config
            })
          }));
        }
      });
    });
    setContentConfig({
      menuItems: filteredMenuItems,
      content: filteredContent,
      matchStateProps: {
        matchState,
        sportType: finalSportType,
        sportEventStatus
      },
      lazyComponentsMap: _lazyComponentsMap
    });
  }, [externalId, forceSportType, shouldIncludeExtraStats, lazyLoader, productId]);
  useEffect(() => {
    if (matchId && forceFetchSportEvent) {
      svs.core.jupiter.get("/sport/1/sportevent/".concat(matchId), data => {
        const {
          sportEvent: {
            sport,
            status
          }
        } = data;
        setContentStates(sport.sportType, status);
      }, error => {
        logger.warn("Failed to fetch match with id ".concat(matchId, ": ").concat(error));
      });
    } else {
      setContentStates(sportType, sportEventStatus);
    }
  }, [matchId, sportType, sportEventStatus, productId, externalId, forceFetchSportEvent, setContentStates]);
  return children(contentConfig);
};
setGlobal('svs.components.sportInfo.contentHandler.ContentHandler', ContentHandler);

 })(window);